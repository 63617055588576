<template>
	<pui-datatable :modelName="modelName" :externalFilter="externalFilter" :readOnly="true" :navigableDetail="false"></pui-datatable>
</template>

<script>
export default {
	data() {
		return {
			modelName: 'visitanomaly'
		};
	},
	computed: {
		externalFilter() {
			let defaultQuay;
			if (this.$store.getters.isTerminalAPMT) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayAPMT;
			} else if (this.$store.getters.isTerminalTTIA) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayTTIA;
			} else if (this.$store.getters.isTerminalAPMTLC) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayAPMTLC;
			} else if (this.$store.getters.isTerminalHPLCT) {
				defaultQuay = this.$store.state.berthplanner.defaultQuayHPLCT;
			} else {
				return {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') }]
				};
			}

			let op = 'eq';
			let data = defaultQuay.id;
			if (defaultQuay.groupIds) {
				op = 'in';
				data = defaultQuay.groupIds;
			}

			return {
				groupOp: 'and',
				groups: [],
				rules: [
					{ field: 'location_id', op, data },
					{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') }
				]
			};
		}
	}
};
</script>
